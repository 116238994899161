import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import BlogPost from "../components/blog/BlogPost";

export default function Blog({ data, location }) {
  //console.log(data)

  return (
    <React.Fragment>
      <SEO title="Blog" url={location.pathname} description="Our blog features content and tips to help you boost your productivity, inspire you, and empower you to take charge of your music career" />
      <section className="blog">
        <div className="container">  
          <div className="blog-posts">
              {data.allContentfulBlogPost.edges.map(post =>
                  <BlogPost key={post.node.slug} post={post.node} />
              )}
          </div>            
          <a href="#" className="btn btn-loadmore btn-block" style={{display:'none'}}>LOAD MORE</a>         
        </div>
      </section>
    </React.Fragment>
  )
}

export const query = graphql`
    query {
    allContentfulBlogPost(sort: {fields: publishDate, order: ASC}) {
      edges {
        node {
          title
          slug
          description {
            description
          }
          thumbnailImage {
            fluid(maxWidth: 980) {
              ...GatsbyContentfulFluid
            }
            title
          }
        }
      }
    }
  }

`